import { Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

@Component({
	selector: 'app-delete-submit',
	standalone: true,
	imports: [
		MatDialogModule,
		MatButtonModule
	],
	templateUrl: './delete-submit.component.html',
	styleUrl: './delete-submit.component.scss'
})
export class DeleteSubmitComponent {
	constructor(private dialogRef: MatDialogRef<DeleteSubmitComponent>) {
	}

	public onConfirm(): void {
		this.dialogRef.close(true);
	}

	public onDismiss(): void {
		this.dialogRef.close(false);
	}
}
