import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { filter, Observable, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IPagination } from '../types/pagination.interface';
import { IFilter } from '../types/filter.interface';
import { ISorting } from '../types/sorting.interface';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteSubmitComponent } from '../components/delete-submit/delete-submit.component';

@Injectable({
	providedIn: 'root'
})
export class BaseApiService {
	public endpoint: string;

	constructor(
		public http: HttpClient,
		public dialog: MatDialog
	) {
		this.endpoint = '';
	}

	public get<T>(endpoint: string, pagination?: IPagination, filters?: IFilter[], sorting?: ISorting): Observable<T> {
		let params: HttpParams = new HttpParams();
		if (pagination) {
			params = params.append('page_size', pagination.pageSize).append('page', pagination.page);
		}
		if (filters?.length) {
			filters.forEach((filter: IFilter) => {
				params = params.append(filter.name, filter.value);
			});
		}
		if (sorting?.sortBy && sorting?.sortOrder) {
			params = params.append('sort_by', sorting.sortBy).append('sort_order', sorting.sortOrder);
		}
		return this.http.get<T>(environment.apiUrl + endpoint, { params });
	}

	public post<T>(endpoint: string, body: any): Observable<T> {
		return this.http.post<T>(environment.apiUrl + endpoint, this._prepareBody(body));
	}

	public put<T>(endpoint: string, body: any): Observable<T> {
		return this.http.put<T>(environment.apiUrl + endpoint, this._prepareBody(body));
	}

	public patch<T>(endpoint: string, body: any): Observable<T> {
		return this.http.patch<T>(environment.apiUrl + endpoint, this._prepareBody(body));
	}

	public delete<T>(endpoint: string, body?: any): Observable<T> {
		return this._openDeleteDialog().pipe(
			filter(Boolean),
			switchMap(() => {
				if (body) {
					const options = {
						headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
						body
					};
					return this.http.delete<any>(environment.apiUrl + endpoint, options);
				} else {
					return this.http.delete<any>(environment.apiUrl + endpoint);
				}
			})
		);
	}

	private _prepareBody(body: object): object {
		const preparedBody: object = body;
		for (const key in preparedBody) {
			preparedBody[key] = this._getValueOrNull(preparedBody[key]);
		}
		return preparedBody;
	}

	private _getValueOrNull(value: any): any {
		if (typeof value === 'number' || typeof value === 'boolean') {
			return value;
		}
		if (!!value) {
			return value;
		}
		return null;
	}

	private _openDeleteDialog(): Observable<boolean> {
		const dialogRef: MatDialogRef<DeleteSubmitComponent> = this.dialog.open(DeleteSubmitComponent, {});
		return dialogRef.afterClosed();
	}
}
